import { defineStore } from "pinia";

type MiddleColumnSlot = "chat" | "pinMessage" | "selectContact" | "createGroup";

export const useAppStateStore = defineStore("app-state", {
  state: () => ({
    middleColumnSlot: undefined as MiddleColumnSlot | undefined,
  }),
  actions: {
    setMiddleColumnSlot (slot: MiddleColumnSlot) {
      this.middleColumnSlot = slot;
    },
    resetMiddleColumn () {
      this.middleColumnSlot = undefined;
    },
  },
});
